const data = {
    webdesign: {
        title: 'web design',
        color: '#1BBD5C',
        text: `<p>Нынче никого не удивить рекламой типа <span class='italics'>«Дизайн за 2 дня – 5000 рублей»</span>.</p><p>К сожалению, ничто в этой жизни не может быть сделано в короткие сроки, при этом не потеряв в качестве. Для меня нет понятия «халтура», к каждому заказу я подхожу максимально ответственно, а сроки всегда называю адекватные и честные. Срывать их я не люблю. С уважением отношусь к своему времени, а главное – ко времени моих клиентов.</p>`,
        images: [
            {   img: 'web-design-1.png',
                imgAlt: 'Юнайтед Лоджистик Системс',
                imgDesc: ['Сайт для логистической компании', '“Юнайтед Лоджистик Системс”'] },
            {   img: 'web-design-2.png',
                imgAlt: 'Московское Здоровье',
                imgDesc: ['Сайт “Московское Здоровье”', 'для НИИОЗММ ДЗМ']},
            {   img: 'web-design-3.png',
                imgAlt: 'Law Free',
                imgDesc: ['Сайт юридической компании', 'Law Free']
            },
            {   img: 'web-design-4.png',
                imgAlt: 'BSN.RU',
                imgDesc: ['Редизайн сайта по недвижимости', 'Большой Сервер Недвижимости (BSN.RU)']
            },
            {   img: 'web-design-5.png',
                imgAlt: 'Infologic',
                imgDesc: ['Сайт медиа-агентства', 'Infologic']
            },
            {   img: 'web-design-6.png',
                imgAlt: 'Ostankino Media Group',
                imgDesc: ['Сайт для компании', 'Ostankino Media Group']
            },
            {   img: 'web-design-7.png',
                imgAlt: 'brainfaq.ru',
                imgDesc: ['Проект портала формата вопрос-ответ', 'brainfaq.ru']
            },
            {   img: 'web-design-8.png',
                imgAlt: 'Kvartinder',
                imgDesc: ['Лендинг для мобильного приложения', 'игрового поиска квартир Kvartinder']
            }
        ],
    },
    graphd : {
        title: 'graphic design',
        color: '#1E9FD7',
        text: '<p>Отбросьте фразы «нужен вкусный дизайн», «поиграем со шрифтами» и доверьтесь, наконец, профессионалу.</p><p> Я подберу по-настоящему красивые образы и визуальные схемы для вашего продукта, баннера или наружной рекламы. А результатом будут заинтересованные клиенты, которых привлечет интересный визуальный стиль вашего продукта.</p>',
        images: [
            {
                img: 'graph-design-1.png',
                imgAlt: 'Банный блог NUDE',
                imgDesc: ['Приглашение на лекции', 'банного блога “NUDE”'] },
            {
                img: 'graph-design-2.png',
                imgAlt: 'Банный блог NUDE',
                imgDesc: ['Приглашение на лекции', 'банного блога “NUDE”']},
            {
                img: 'graph-design-3.png',
                imgAlt: 'mipim 2019 cannes',
                imgDesc: ['Реклама российской делегации', ' на MIPIM 2019 в Каннах']
            },
            {   img: 'graph-design-4.png',
                imgAlt: 'PFM Profit',
                imgDesc: ['Баннер PMF Profit,', 'мероприятия по девелопменту']
            },
            {   img: 'graph-design-5.png',
                imgAlt: 'formula city',
                imgDesc: ['Поздравление с Днем Строителя', 'от компании Formula City']
            },
            {   img: 'graph-design-6.png',
                imgAlt: 'polin magazin квартирник',
                imgDesc: ['Приглашение Garage Sale', 'от эко-магазина Polinmagazin']
            },
            {   img: 'graph-design-7.png',
                imgAlt: 'polin magazin черная пятница',
                imgDesc: ['Распродажа “Черная пятница”', 'от эко-магазина Polinmagazin']
            },
            {   img: 'graph-design-8.png',
                imgAlt: 'терапия ДР',
                imgDesc: ['Приглашение на день рождения', 'бара Терапия']
            },
            {   img: 'graph-design-9.png',
                imgAlt: 'shum agency лекция',
                imgDesc: ['Приглашение на лекцию Shum Agency', 'в Порт Севкабель']
            }]
    },
    print : {
        title: 'print design',
        color: '#1C43D0',
        text: '<p>От ярких листовок до огромных билбордов 6 на 3 метра недалеко от вашего дома. От строгих визиток на софт-тач картоне до 200-страничных энциклопедий и журналов. </p><p>Макеты «на печать» для меня не пустой звук. Отработаю сам дизайн и обсужу с типографией максимально качественный способ вывода, чтобы вас распирала гордость за размещенную на растяжке рекламу или за визитку, которой хочется хвастаться перед коллегами. </p>',
        images: [
            {
                img: 'print-1.png',
                imgAlt: 'доверие потребителя',
                imgDesc: ['Программа бизнес-завтрака ежегодного', 'конкурса “Доверие Потребителя”']
            },
            {
                img: 'print-2.png',
                imgAlt: 'флаеры для бара Терапия',
                imgDesc: ['Флаеры для пивного бара', '“Терапия”']},
            {
                img: 'print-3.png',
                imgAlt: 'семинар о сборе мусора',
                imgDesc: ['Программа семинара о раздельном', 'сборе мусора']
            },
            {   img: 'print-4.png',
                imgAlt: 'расписание паромов и поездов',
                imgDesc: ['Евробуклет с расписанием паромов', 'и поездов для сервиса Bilet77']
            },
            {   img: 'print-5.png',
                imgAlt: 'Брендмауер 5FIT',
                imgDesc: ['Брендмауер сети спортивных залов', 'EMS-фитнеса 5FIT']
            },
            {   img: 'print-6.png',
                imgAlt: 'Энциклопедия Ветераны Великой отечественной',
                imgDesc: ['Энциклопедия “Ветераны Великой отечественной', 'войны: московские врачи и медсестры”']
            },
            {   img: 'print-7.png',
                imgAlt: 'Энциклопедия Врачи и медсестры Москвы',
                imgDesc: ['Энциклопедия “Врачи и медсестры Москвы:', 'лучшие в профессии”']
            },
            {   img: 'print-8.png',
                imgAlt: 'Временное практическое руководство COVID-19',
                imgDesc: ['Временное практическое руководство COVID-19.', 'Опыт столичного здравоохранения']
            }]
    },
    branding : {
        title: 'branding',
        color: '#B41DDA',
        text: '<p>Логотипы, фирменные стили, упаковка – за свою карьеру я занимался всеми нюансами брендинга, и порадовал множество компаний. Стараюсь поймать «тот самый» крутой стиль, о котором вы мечтали.</p> <p>В оформлении придерживаюсь минималистичного дизайна, потому что считаю, что «больше» – не всегда значит «лучше». Чистый и лаконичный фирменный стиль всегда дольше остается свежим и не надоедает, чем перегруженный.</p>',
        images: [
            {
                img: 'logos-1.png',
                imgAlt: 'Логотип для бара “Терапия”',
                imgDesc: ['Логотип для бара', '“Терапия”']
            },
            {
                img: 'logos-2.png',
                imgAlt: 'Логотип банного блога Nude',
                imgDesc: ['Логотип банного блога', 'Nude']
            },
            {
                img: 'logos-3.png',
                imgAlt: 'Логотип Комиссии по недвижимости СПБ',
                imgDesc: ['Логотип Комиссии по недвижимости', 'Санкт-Петебурга и Ленинградской области']
            },
            {   img: 'logos-4.png',
                imgAlt: 'логотип конференции Smart FM',
                imgDesc: ['Логотип конференции по facility-', 'менеджменту Smart FM ']
            },
            {   img: 'logos-5.png',
                imgAlt: 'Логотип RBI PM',
                imgDesc: ['Логотип управляющей компании', 'RBI PM']
            },
            {   img: 'logos-6.png',
                imgAlt: 'Логотип МОРКОНТТРАНС',
                imgDesc: ['Логотип логистической компании', 'МОРКОНТТРАНС']
            },
            {   img: 'logos-7.png',
                imgAlt: 'Логотип Envoys Capital',
                imgDesc: ['Логотип инвестиционной компании', 'Envoys Capital']
            },
            {   img: 'logos-8.png',
                imgAlt: 'Временное практическое руководство COVID-19',
                imgDesc: ['Логотип ежегодной премии', 'Russian Coworking Awards']
            },
            {   img: 'logos-9.png',
                imgAlt: 'Временное практическое руководство COVID-19',
                imgDesc: ['Логотип и упаковка товаров', 'эко-магазина Polin Magazin']
            }
        ]
    },
    ui : {
        title: 'ui/ux',
        color: '#DC186A',
        text: '<p>Мобильные приложения стали неотъемлемой частью нашей жизни. Мы лезем в смартфон, чтобы заказать еду или прочитать свежие новости, посмотреть в соцсетях как живут наши друзья или перевести денег родственникам в другой город.</p><p> Разработка дизайна приложений – это всегда очень ответственно и трудозатратно, но в результате я гарантирую приятное в использовании и привлекательное решение, которое порадует как заказчика, так и пользователей.</p>',
        images: [
            {   img: 'ui-1.png',
                imgAlt: 'Концепт мобильного приложения Routes',
                imgDesc: ['Концепт мобильного приложения', 'Routes']
            },
            {   img: 'ui-2.png',
                imgAlt: 'Мобильное приложение для газеты “Московская Медицина. Cito”',
                imgDesc: ['Мобильное приложение для газеты', '“Московская Медицина. Cito”']
            },
            {   img: 'ui-3.png',
                imgAlt: 'Прототип мобильного приложения для поиска квартир Kvartinder',
                imgDesc: ['Прототип мобильного приложения', 'для поиска квартир Kvartinder']
            }
        ]
    }
}

export default data;
