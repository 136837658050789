import React from "react";
import ReactDOM from "react-dom";

import "./sass/style.sass"

import App from "./components/App/App.jsx";


ReactDOM.render(
    <App />,
    document.querySelector(`#root`)
);
